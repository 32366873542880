// app/javascript/controllers/timer_controller.js
import { Controller } from "@hotwired/stimulus"
import JSConfetti from "js-confetti"

export default class extends Controller {
  static targets = ["time"]

  connect() {
    this.resetTimer()
  }

  disconnect() {
    clearInterval(this.timer)
  }

  resetTimer() {
    clearInterval(this.timer)

    this.timeRemaining = this.durationValue || 15
    this.updateDisplay()

    this.timer = setInterval(() => {
      this.timeRemaining -= 1
      this.updateDisplay()

      if (this.timeRemaining <= 0) {
        clearInterval(this.timer)
        this.endGame()
      }
    }, 1000)
  }

  updateDisplay() {
    this.timeTarget.textContent = `${this.timeRemaining}s`
  }

  endGame() {
    let form = document.querySelector("#end_game_form")
    let button = form.querySelector("button")

    button.click()

    const confetti = new JSConfetti()
    confetti.addConfetti()
  }
}