document.addEventListener('turbo:load', () => {
  // Function to toggle the dropdown
  function toggleDropdown($el) {
    $el.classList.toggle('is-active');
  }

  // Close all dropdowns
  function closeAllDropdowns() {
    document.querySelectorAll('.dropdown.is-active').forEach(($dropdown) => {
      $dropdown.classList.remove('is-active');
    });
  }

  // Add a click event on the dropdown trigger to toggle the dropdown
  document.querySelectorAll('.dropdown-trigger').forEach(($trigger) => {
    const $dropdown = $trigger.closest('.dropdown');

    $trigger.addEventListener('click', (event) => {
      event.stopPropagation();
      closeAllDropdowns();
      toggleDropdown($dropdown);
    });
  });

  // Close dropdowns if clicking outside
  document.addEventListener('click', (event) => {
    if (!event.target.closest('.dropdown')) {
      closeAllDropdowns();
    }
  });

  // Close dropdown on "Escape" key
  document.addEventListener('keydown', (event) => {
    if (event.key === "Escape") {
      closeAllDropdowns();
    }
  });
});